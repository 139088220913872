.view {
	padding: 0;
}
.view-header {
	gap: 10px;
	padding: 20px;
	display: flex;
	align-items: center;
}
.view-header__back-btn {
	width: 18px;
	height: 18px;
	opacity: 0.5;
	cursor: pointer;
}
.view-header__back-btn:hover {
	opacity: 1;
}

.view-header__title {
	font-size: 30px;
	font-weight: 600px;
}

.view-body {
	display: flex;
	overflow: auto;
	height: calc(100vh - 150px);
	border-top: 1px solid var(--primary-border-color);
}

.nav-links {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
}

.nav-link {
	cursor: pointer;
	font-size: 15px;
	padding: 6px 14px;
	display: block;
	color: var(--info-color);
	border: 1px solid var(--primary-border-color);
}

.nav-link:first-child {
	border-right: transparent;
	border-radius: 5px 0 0 5px;
}

.nav-link:last-child {
	border-radius: 0 5px 5px 0;
}

.nav-link--active {
	color: #ffffff;
	background-color: var(--primary-color);
	border-color: var(--primary-color);
}

.preview-container {
	width: 750px;
	border-right: 1px solid var(--primary-border-color);
}

.form-container {
	width: calc(100vw - 875px);
}

.preview-container__header,
.form-container__header {
	padding: 10px 20px;
	border-bottom: 1px solid var(--primary-border-color);
}

.preview-container__title,
.form-container__title {
	font-size: 20px;
	font-weight: 600;
}

.preview-container__body {
	padding: 20px;
	overflow: auto;
	height: calc(100vh - 210px);
}

.form-container__body {
	padding: 20px;
	overflow: auto;
	height: calc(100vh - 270px);
}
.preview-card {
	width: 500px;
	height: 280px;
	display: block;
	overflow: hidden;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}


.form-container__footer {
	gap: 10px;
	height: 60px;
	display: flex;
	padding: 10px 20px;
	align-items: center;
	justify-content: flex-end;
	border-top: 1px solid var(--primary-border-color);
}
