
.modal__backdrop {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 15;
	opacity: 0.6;
	position: fixed;
	background: var(--modal-backdrop-bg-color);
}

.modal {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 20;
	position: fixed;
}

.modal--top {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
}

.modal__container {
	width: 550px;
	height: max-content;
	background: var(--white-color);
}

.modal__container--top {
	border-radius: 0 0 8px 8px;
}

.modal__header {
	display: flex;
	padding: 10px 20px;
	align-items: center;
}

.modal__title {
	font-size: 22px;
	font-weight: 600;
	margin-right: auto;
}

.modal__close-icon {
	height: 20px;
	width: 20px;
	cursor: pointer;
}

.modal__body {
	padding: 10px 20px;
}

.modal__footer {
	display: flex;
	column-gap: 10px;
	padding: 10px 20px;
	align-items: center;
	justify-content: flex-end;
}