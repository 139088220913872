.table-cell-icon {
	cursor: pointer;
	width: 18px;
	height: 18px;
	margin: 0 5px;
	color: var(--info-color);
}

.table-cell-icon:first-child {
	margin-left: 0;
}

.table-cell-icon:last-child {
	margin-right: 0;
}

.table-cell-icon:hover {
	color: var(--primary-color);
}

.table-cell-link {
	cursor: pointer;
}
