.circle-loader {
	position: relative;
	width: auto;
	height: auto;
}

.circle-loader div {
	height: 10px;
	width: 10px;
	background-color: var(--primary-color);
	border-radius: 50%;
	position: absolute;
	animation: 0.8s opaque ease-in-out infinite both;
}

.circle-loader > div:nth-child(1) {
	top: -25px;
	left: 0;
}
.circle-loader > div:nth-child(2) {
	top: -17px;
	left: 17px;
	-webkit-animation-delay: 0.1s;
	animation-delay: 0.1s;
}
.circle-loader > div:nth-child(3) {
	top: 0;
	left: 25px;
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
.circle-loader > div:nth-child(4) {
	top: 17px;
	left: 17px;
	-webkit-animation-delay: 0.3s;
	animation-delay: 0.3s;
}
.circle-loader > div:nth-child(5) {
	top: 25px;
	left: 0;
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s;
}
.circle-loader > div:nth-child(6) {
	top: 17px;
	left: -17px;
	-webkit-animation-delay: 0.5s;
	animation-delay: 0.5s;
}
.circle-loader > div:nth-child(7) {
	top: 0;
	left: -25px;
	-webkit-animation-delay: 0.6s;
	animation-delay: 0.6s;
}
.circle-loader > div:nth-child(8) {
	top: -17px;
	left: -17px;
	-webkit-animation-delay: 0.7s;
	animation-delay: 0.7s;
}

@keyframes opaque {
	0% {
		opacity: 0.1;
	}
	40% {
		opacity: 1;
	}
	80% {
		opacity: 0.1;
	}
	100% {
		opacity: 0.1;
	}
}
.app-container {
	width: 100vw;
	height: 100vh;
}
.app-home-container {
	display: flex;
	height: inherit;
}

.app-sidemenu {
	width: 260px;
	flex-shrink: 1;
	color: #fff;
	padding: 15px 15px 15px;
	border-radius: 0 15px 0 0;
	background-color: var(--primary-color);
}

.app-title {
	font-size: 26px;
	padding-bottom: 20px;
}

.app-navigation-list {
	padding: 10px;
}

.app-navigation-list-item {
	padding: 8px 12px;
	display: flex;
	align-items: center;
	border-radius: 8px;
	color: #9ba3af;
}
.app-navigation-list-item:hover,
.app-navigation-list-item.active {
	color: #fff;
	background-color: #182c48;
}
.app-navigation-list-item-icon {
	width: 20px;
	height: 20px;
}
.app-navigation-list-item-label {
	font-size: 14px;
	margin-left: 5px;
}

.app-content-container {
	width: calc(100vw - 260px);
	background: #fff;
}

.app-top-bar {
	top: 0;
	right: 0;
	left: 260px;
	height: 55px;
	display: flex;
	position: fixed;
	padding: 8px 15px;
	align-items: center;
	border-bottom: 1px solid var(--primary-border-color);
}

.app-no-data {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.app-no-data-icon-container {
	padding: 20px;
	margin-bottom: 15px;
	border-radius: 50%;
	background-color: #f9f9fa;
}
.app-no-data-icon {
	height: 150px;
	width: 150px;
	color: #cacad1;
}

.app-title-bar {
	height: 60px;
	padding: 10px 20px;
	display: flex;
	align-items: center;
	background-color: var(--primary-color);
}

.app-title {
	color: #fff;
	font-size: 25px;
	font-weight: 600;
}

.app-content {
	padding: 20px;
	overflow: auto;
	margin-top: 60px;
	height: calc(100vh - 60px);
}
.app-logout-btn {
	width: 20px;
	height: 20px;
	cursor: pointer;
	color: #b5b5b5;
}
.app-logout-btn:hover {
	color: var(--primary-color);
}
.update-profile-card {
	width: 80%;
	padding: 20px;
	border-radius: 6px;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.profile-lead-card {
	width: 450px;
	display: block;
	margin: auto;
	padding: 20px;
	min-width: 320px;
	overflow: hidden;
	border-radius: 6px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.profile-card {
	display: flex;
	margin: auto;
	min-width: 300px;
	max-width: 550px;
	overflow: hidden;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.profile-card .content {
	flex: 1;
	padding: 20px;
	min-width: 450px;
}

.profile-card .qr {
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: var(--primary-color);
}

.profile-card .qr .image {
	height: 250px;
	width: 250px;
	margin-bottom: 20px;
	border-radius: 10px;
}

.profile-card .user-image {
	padding: 20px 20px 10px 20px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}
.profile-card .user-image .image {
	width: 150px;
	height: 150px;
	border-radius: 50%;
	border: 1px solid var(--primary-color);
}

@media only screen and (max-width: 500px) {
	.profile-card {
		margin: auto;
		display: block;
		height: max-content;
	}
	.profile-card .content {
		min-width: 300px;
	}
	.profile-card .qr .image {
		width: 200px;
		height: 200px;
	}

	.profile-lead-card {
		margin: auto 10px;
	}
}

.display-profile-icon {
	width: 25px;
	height: 25px;
	color: #b7bbc0;
}
.display-profile-icon:hover {
	color: var(--primary-color);
}

.view-profile-layout {
	display: flex;
	width: 100%;
	height: 100%;
}

/* Components */
/* .btn {
	display: flex;
	padding: 8px 12px;
	border-radius: 5px;
	align-items: center;
} */

.btn-icon {
	color: #8a8a8a;
}

.btn-icon:hover {
	color: var(--primary-color);
}
.btn.sm {
	padding: 6px 10px;
	font-size: 12px;
}
.btn.primary {
	color: #fff;
	background-color: var(--primary-color);
}
.btn.primary-outlined {
	color: var(--primary-color);
	background-color: #fafafa;
	border: 1px solid var(--primary-border-color);
}
.btn-loader-icon {
	width: 15px;
	height: 15px;
	margin-left: 5px;
	animation: spin 1s infinite linear;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.styled-input-image-thumbnail {
	width: 120px;
	height: 120px;
	overflow: hidden;
	border: 1px solid var(--primary-border-color);
	border-radius: 10px;
}

.styled-input,
.styled-input-phone-container {
	width: 100%;
	font-size: 14px;
	padding: 6px 12px;
	border-radius: 8px;
	border: 1px solid var(--input-primary-border-color);
}

.styled-input-phone-button {
	background-color: transparent !important;
	border-color: var(--primary-border-color) !important;
}

.styled-input-phone {
	width: 100% !important;
	font-size: 14px;
	padding-top: 8px !important;
	padding-bottom: 8px !important;
	border: 1px solid var(--primary-border-color) !important;
	border-radius: 8px;
}
.styled-input-phone::placeholder,
.styled-input::placeholder {
	font-weight: 300;
	@apply text-gray-400;
}
.styled-input:focus {
	@apply border-green-500;
}
.styled-input.invalid,
.styled-input.invalid:focus {
	@apply border-red-600;
}

.styled-input.info {
	border: none;
	background-color: #f9f9fb;
}

.styled-table-container {
	overflow-y: auto;
	height: calc(100vh - 180px);
}
.styled-table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0;
}

.styled-table-header {
	top: 0;
	position: sticky;
	font-weight: 500;
	font-size: 14px;
	text-align: left;
	padding: 8px 12px;
	color: #1f2534;
	background: #fff;
	border-bottom: 1px solid #b6b6b6;
}

.styled-table-row-data {
	font-weight: 400;
	text-align: left;
	font-size: 13px;
	padding: 8px 12px;
	word-break: break-all;
	color: #4d4f51;
	border-bottom: 1px solid var(--primary-border-color);
}

.styled-table-row-data .phone {
	border: none;
	font-weight: 400;
	text-align: left;
	font-size: 13px;
	color: #4d4f51;
}

.styled-table-loader-row {
	padding: 8px 10px;
	text-align: center;
	color: var(--primary-color);
}

.styled-table-loader {
	width: 24px;
	height: 24px;
	animation: spin 1s infinite linear;
}

.toast {
	color: #484e4b;
	border-radius: 8px;
	overflow: hidden;
	border-left: 8px solid transparent;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
		rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.toast .container {
	display: flex;
	min-width: 350px;
	align-items: center;
	padding: 8px 10px;
}

.toast .icon {
	width: 28px;
	height: 28px;
	margin-right: 10px;
}

.toast .text {
	flex: 1;
	margin-right: 10px;
}

.toast .close-icon {
	width: 20px;
	height: 20px;
}
.toast.success {
	border-left-color: #3bc279;
	background-color: #c5f7dc;
}
.toast.success .icon,
.toast.success .close-icon {
	color: #3bc279;
}

.toast.error {
	border-left-color: #e9594b;
	background-color: #fecfca;
}
.toast.error .icon,
.toast.error .close-icon {
	color: #e9594b;
}

.toast.info {
	border-left-color: #3e83e5;
	background-color: #cde1fe;
}
.toast.info .icon,
.toast.info .close-icon {
	color: #3e83e5;
}

.app-shadow {
	box-shadow: rgba(60, 64, 67, 0.2) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.1) 0px 1px 3px 1px;
}
