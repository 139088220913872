.container--flex {
	display: flex;
}

.container--load-more {
	width: 100%;
	display: flex;
	margin-top: 10px;
	align-items: center;
	justify-content: center;
}

.container--portals {
	width: 100%;
	margin: 0 auto;
	min-width: 600px;
	max-width: 1100px;
}

.container--portals__cards {
	gap: 25px;
	display: flex;
	flex-wrap: wrap;
}

.container--users__cards {
	gap: 25px;
	display: flex;
	flex-wrap: wrap;
}

.container--external-apps__cards {
	gap: 25px;
	display: flex;
	flex-wrap: wrap;
}

.container--profile__category__header {
	height: 55px;
	display: flex;
	padding: 10px;
	column-gap: 10px;
	align-items: center;
	border-bottom: 1px solid var(--primary-border-color);
}
.container--profile__category__body {
	width: 100%;
	padding: 20px;
	overflow: auto;
	height: calc(100vh - 115px);
}

.container--profile__category__footer {
	width: 100%;
	height: 55px;
	display: flex;
	padding: 10px;
	column-gap: 10px;
	align-items: center;
	justify-content: flex-end;
	border-top: 1px solid var(--primary-border-color);
}

.container--profile--lead-gen__category__body {
	padding: 0;
	overflow: none;
	display: flex;
	height: calc(100vh - 170px);
}

.container--profile--lead-gen__zoho-crm-fields {
	width: 300px;
	padding: 20px;
	overflow: auto;
	border-right: 1px solid var(--primary-border-color);
}

.container--profile--lead-gen__system-fields {
	width: 400px;
	padding: 20px;
	overflow: auto;
	border-right: 1px solid var(--primary-border-color);
}

.container--profile--lead-gen__preview {
	width: calc(100vw - 1020px);
	padding: 20px;
	overflow: auto;
}
