.view {
	padding: 20px;
	overflow: auto;
	height: calc(100vh - 56px);
}

.view--app {
	padding: 0;
	height: 100vh;
	width: 100vw;
}

.view--profile-picker {
	height: 100%;
	padding: 0;
	width: calc(100vw - 320px);
}

.view--header {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}


.view--actions{
	gap: 10px;
	display: flex;
	margin-left: 5px;
	align-items: center;
}