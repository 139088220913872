.header {
	top: 0;
	left: 0;
	right: 0;
	z-index: 5;
	height: 56px;
	display: flex;
	position: fixed;
	padding: 0 10px;
	align-items: center;
	background-color: var(--white-color);
	border-bottom: 1px solid var(--grey-color-100);
}

/* Container */
.container--center,
.container__card-selection {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.container--full {
	width: 100%;
	height: 100%;
}
.container__main {
	margin-top: 56px;
	height: calc(100vh - 56px);
}

.container--flex {
	display: flex;
	align-items: center;
}
.container__header-actions {
	display: flex;
	column-gap: 6px;
	align-items: center;
}
.container__title {
	display: flex;
	align-items: center;
	justify-content: center;
}
.container__multi-portal {
	width: 100%;
	height: 100%;
	overflow: auto;
}
.container__multi-portal-cards {
	gap: 32px;
	display: flex;
	flex-wrap: wrap;
}

.container__portal {
	width: 100%;
	height: 100%;
	display: flex;
}
.container__portal-content {
	width: calc(100vw - 120px);
}

.container__settings,
.container__profile {
	height: 100%;
	display: flex;
}
.container__settings-content {
	overflow: auto;
	width: calc(100vw - 320px);
}

.container__update-information {
	padding: 40px;
}

.container__card-selection-card-section {
	padding: 20px;
	width: calc(100% - 550px);
}

.container__card-selection-form {
	width: 550px;
	padding: 20px;
	border-right: 1px solid var(--primary-border-color);
}

.container__card-selection-card-preview {
	width: 100%;
	height: 300px;
	overflow: hidden;
	border-radius: 5px;
}

.container__form-input-group {
	margin-bottom: 20px;
}

.container__view-title {
	display: flex;
	padding: 20px;
	align-items: center;
	margin-bottom: 20px;
}

.container__view-table {
	overflow-y: auto;
	height: calc(100vh - 155px);
}

.container__create-card {
	width: 100%;
	height: 100%;
	padding: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.container__create-card__select-card-design {
	gap: 16px;
	display: flex;
	flex-wrap: wrap;
}

.container--activation-code-lable {
	display: flex;
	column-gap: 10px;
	align-items: center;
	justify-content: space-between;
}

.container--btn--profile-picker-view {
	display: flex;
	padding: 0 20px;
	height: inherit;
	align-items: center;
	justify-content: center;
}

.container--card--profile-picker-view {
	padding: 20px;
	width: calc(100vw - 510px);
}

.container--center--horizontal {
	display: flex;
	justify-content: center;
}

/* Sidebar */

.sidebar {
	height: 100%;
}

.sidebar--primary {
	width: 120px;
	row-gap: 10px;
	display: flex;
	padding: 8px 14px;
	flex-direction: column;
	background-color: var(--sidebar-primay-bg-color);
}
.sidebar--secondary {
	width: 200px;
	padding: 8px 14px;
	flex-direction: column;
	background-color: var(--sidebar-secondary-bg-color);
}

/* Cards */

.card__create-card,
.card__update-card,
.card__activate-card {
	width: 40rem;
	padding: 20px;
}

.card--card-preview,
.card--card-approval {
	padding: 20px;
}

.card--card-approval__body,
.card__activate-card__body {
	margin-bottom: 15px;
}

.card__activate-card__body:last-child {
	margin-bottom: 0;
}

.card--card-approval__footer,
.card__activate-card__footer {
	gap: 10px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.card--form-field {
	display: flex;
	width: 400px;
	column-gap: 10px;
	padding: 8px 12px;
	background: #fff;
	align-items: center;
}

.card--profile-viewer {
	width: 100%;
	padding: 20px;
	margin: 0 auto;
	max-width: 500px;
	min-height: 450px;
}

.card--profile-form {
	width: 100%;
	padding: 20px;
	margin: 0 auto;
	max-width: 500px;
}
/* 
.card--external-app {
	width: 250px;
	height: 150px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.card--external-app__title {
	font-size: 20px;
	font-weight: 600;
}

.card--external-app__connection-status {
	font-size: 13px;
	font-weight: 500;
	color: var(--input-primary-label-color);
}

.card--external-app__icon {
	width: 40px;
	height: 40px;
} */

.card--card-template {
	width: 530px;
	height: 400px;
	padding: 10px;
}

.card--card-template__template {
	width: 505px;
	height: 290px;
	margin-bottom: 10px;
}
/* Navlink */
.navlink {
	padding: 6px 12px;
	border-radius: 6px;
}

.navlink--primary {
	opacity: 0.2;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	color: var(--navlink-primary-inactive-color);
}

.navlink--primary--active,
.navlink--primary:hover {
	opacity: 1;
	color: var(--white-color);
	background-color: var(--navlink-primary-bg-color);
}

.navlink--secondary {
	display: flex;
	margin-bottom: 10px;
	align-items: center;
	justify-content: flex-start;
	color: var(--navlink-secondary-inactive-color);
}

.navlink--secondary--active,
.navlink--secondary:hover {
	color: var(--white-color);
	background-color: var(--navlink-primary-bg-color);
}

/* Icon class */
.icon__navlink--primary {
	width: 24px;
	height: 24px;
}
.icon__app {
	width: 40px;
	height: 40px;
}
.icon__logout {
	width: 20px;
	height: 20px;
}
/* Title  */

.title__app {
	font-size: 18px;
	margin-left: 5px;
	font-weight: 500;
}

.title__navlink--primary,
.title__navlink--secondary {
	font-size: 14px;
}

.title__navlink--secondary {
	opacity: 0.5;
}

.navlink--secondary--active .title__navlink--secondary,
.navlink--secondary:hover .title__navlink--secondary {
	opacity: 1;
}

/* Badge */



/* Button */
/* 
.btn {
	display: flex;
	column-gap: 5px;
	font-size: 14px;
	padding: 6px 14px;
	border-radius: 5px;
	align-items: center;
}

.btn--primary {
	color: var(--white-color);
	background-color: var(--primary-color);
}

.btn--outlined {
	border: 1px solid var(--primary-border-color);
}
.btn__file-input {
	height: 100%;
	display: flex;
	padding: 0 8px;
	font-size: 13px;
	align-items: center;
	color: var(--white-color);
	background-color: var(--primary-color);
	border-right: 1px solid var(--primary-border-color);
}
.btn__icon {
	opacity: 0.5;
}
.btn__icon:hover {
	opacity: 1;
}

.btn__icon--table {
	width: 17px;
	height: 17px;
}

.btn__icon--modal {
	width: 20px;
	height: 20px;
}

.btn--icon {
	opacity: 0.6;
}

.btn--icon--sm {
	width: 18px;
	height: 18px;
}
.btn--icon--profile-picker {
	width: 24px;
	height: 24px;
}

.btn--profile-picker {
	padding: 10px;
	border-radius: 50%;
	background: #f5f4f4;
	border: 1px solid var(--primary-border-color);
} */

/* Form Inputs  */

.input {
	width: 100%;
	font-size: 14px;
	padding: 8px 14px;
	border-radius: 8px;
	border: 1px solid var(--input-primary-border-color);
}

.input:focus {
	border-color: var(--input-primary-focus-border-color);
}

.input__file {
	padding: 0;
	width: 100%;
	height: 35px;
	display: flex;
	font-size: 14px;
	overflow: hidden;
	align-items: center;
}

.input--checkbox {
	display: none;
}
.input--checkbox__container {
	display: flex;
	column-gap: 10px;
	align-items: center;
}
.input--checkbox__icon {
	width: 15px;
	height: 15px;
	display: block;
	cursor: pointer;
	position: relative;
	border-radius: 2px;
	border: 1px solid var(--primary-border-color);
}

.input--checkbox:checked + .input--checkbox__icon::after {
	content: '';
	left: 4px;
	bottom: 2px;
	width: 6px;
	height: 12px;
	position: absolute;
	border: solid var(--white-color);
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}

.input--checkbox:checked + .input--checkbox__icon {
	border-color: var(--primary-color);
	background-color: var(--primary-color);
}

.input--checkbox__label {
	font-size: 14px;
	font-weight: 300;
	color: var(--input-primary-label-color);
}

.input--error,
.input--error:focus {
	border-color: var(--input-error-border-color);
}

.input::placeholder,
.text__placeholder {
	font-weight: 300;
	color: var(--input-primary-placeholder-color);
}

.input--readonly {
	color: #7f7f7f;
	cursor: not-allowed;
	background-color: #f0f0f0;
}

.text__file-input {
	padding: 8px 14px;
}

.text__form-title {
	font-size: 22px;
	margin-bottom: 20px;
}

.text__form-field-label {
	display: block;
	font-size: 14px;
	font-weight: 300;
	margin-bottom: 2px;
	color: var(--input-primary-label-color);
}

.text__form-field-error {
	font-size: 13px;
	color: var(--input-error-color);
}

.text__view-title {
	font-size: 24px;
}
.text__modal-title {
	font-size: 20px;
}
.text--15 {
	font-size: 15px;
}
/* table */

.table1 {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0;
}

.table--primary__table-header {
	top: 0;
	z-index: 1;
	height: 45px;
	position: sticky;
	font-weight: 500;
	font-size: 14px;
	text-align: left;
	padding: 8px 12px;
	color: var(--table-primary-header-text-color);
	background: var(--table-primary-header-bg-color);
	border-bottom: 1px solid var(--table-primary-header-border-color);
}

.table--primary__table-data {
	height: 45px;
	font-weight: 400;
	text-align: left;
	font-size: 13px;
	padding: 8px 12px;
	word-break: break-all;
	color: var(--table-primary-table-data-text-color);
	border-bottom: 1px solid var(--table-primary-table-data-border-color);
}

.table--primary__table-data--menu {
	display: flex;
	column-gap: 10px;
	align-items: center;
	justify-content: space-between;
}

/* Image */
.img--card {
	width: 600px;
	height: 325px;
}

/* Tabs */
.tabs {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	justify-content: center;
}

/* Tab */
.tab {
	font-size: 14px;
	cursor: pointer;
	padding: 6px 14px;
	border-radius: 6px;
	border: 1px solid var(--primary-border-color);
}

.tab:not(:first-child) {
	border-left: 0;
}

.tab--outlined--active {
	color: var(--primary-color);
	@apply bg-green-100;
}

.tab--outlined:not(:first-child):not(:last-child) {
	border-radius: 0;
}

.tab--outlined:first-child {
	border-radius: 6px 0 0 6px;
}

.tab--outlined:last-child {
	border-radius: 0 6px 6px 0;
}

/* Views */
/* .view {
	width: 100%;
	height: 100%;
	padding: 20px;
} */

.view--profile-viewer {
	padding: 40px;
	overflow: auto;
}

/* .view--profile-picker {
	display: flex;
} */

.view--form-field {
	padding: 0;
}

/* Headers */
.view__header {
	gap: 10px;
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}

.view__header--form-field {
	padding: 20px;
	margin-bottom: 0;
	border-bottom: 1px solid var(--primary-border-color);
}

/* Body */
.view__body {
	height: calc(100vh - 155px);
}

.view__body--table {
	overflow-y: auto;
}

.view__body--card-preview,
.view__body--card-approval,
.view__body--create-card,
.view__body--update-card {
	display: flex;
	align-items: center;
	justify-content: center;
}

.view__body--form-field {
	padding: 20px;
	row-gap: 15px;
	display: flex;
	overflow-y: auto;
	align-items: center;
	flex-direction: column;
	height: calc(100vh - 210px);
	background-color: #fdfdfd;
}

.view__body--external-apps {
	gap: 10px;
	display: flex;
	flex-wrap: wrap;
}

.view__footer--form-field {
	display: flex;
	padding: 20px;
	align-items: center;
	justify-content: flex-end;
	border-top: 1px solid var(--primary-border-color);
}

.view__body--choose-card-template {
	gap: 10px;
	display: flex;
	flex-wrap: wrap;
}

.menu--table {
	display: flex;
	column-gap: 10px;
	align-items: center;
}

/* modal */
/* 
.modal {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	position: fixed;
}
.modal__overlay {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	position: fixed;
	background-color: rgba(0, 0, 0, 0.2);
}
.modal--top {
	display: flex;
	justify-content: center;
}

.modal__container {
	display: flex;
	flex-direction: column;
	background-color: #fff;
}

.modal__container--top {
	width: 600px;
	overflow: auto;
	height: max-content;
	border-radius: 0 0 5px 5px;
}

.modal__container--top--delete {
	height: 250px;
}

.modal__header {
	display: flex;
	padding: 10px 20px;
	column-gap: 10px;
	align-items: center;
	justify-content: space-between;
}

.modal__body {
	flex-grow: 1;
	padding: 0 20px;
}

.modal__body--create-form {
	min-height: 200px;
}

.modal__body--form-field {
	min-height: 380px;
}

.modal__footer {
	display: flex;
	padding: 10px 20px;
	column-gap: 10px;
	align-items: center;
	justify-content: flex-end;
} */

/* Color */
.color--info {
	color: var(--info-color);
}

/* Font-sizes */
.font-size--13 {
	font-size: 13px;
}
.font-size--15 {
	font-size: 15px;
}

.typography--h5 {
	font-size: 22px;
	font-weight: 600;
}

.font-size--16 {
	font-size: 16px;
}

.font-size--18 {
	font-size: 18px;
}

.font-size--20 {
	font-size: 20px;
}
/* Card related css */



/* Profile Related Css */
.profile--portfolio__container {
	width: 100%;
	height: 100%;
}

.profile--portfolio__body {
	width: 100%;
	padding: 40px 20px;
	height: calc(100% - 80px);
}

.profile--portfolio__footer {
	height: 80px;
	display: flex;
	align-items: center;
}

/* Profile Template Nodes */

