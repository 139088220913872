.title--page {
	font-size: 30px;
	font-weight: 600;
	margin-right: auto;
}

.title--portals {
    color: #fff;
    font-size: 38px;
	margin-bottom: 20px;
}
