.link {
    font-size: 14px;
	padding: 5px 10px;
    width: max-content;
	border-radius: 5px;
}

.link--primary {
	color: var(--white-color);
	background-color: var(--primary-color);
}
