.card {
	border-radius: 10px;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.card--profile-template {
	margin: 0 auto;
	min-width: 320px;
	max-width: 500px;
	padding: 15px 20px;
}

.card--update-information {
	width: 100%;
	padding: 20px;
	margin: 0 auto;
	min-width: 600px;
	max-width: 1000px;
}

.card--portal {
	width: 240px;
	display: flex;
	height: 300px;
	overflow: hidden;
	cursor: pointer;
	flex-direction: column;
	background-color: #fff;
}

.card--portal--create,
.card--user--invite {
	padding: 20px;
}

.card--portal--create__container,
.card--user--invite__container {
	height: 100%;
	display: flex;
	border-radius: 5px;
	align-items: center;
	justify-content: center;
	background-color: var(--secondary-color);
	border: 1px dashed var(--primary-color);
}

.card--portal--create__icon,
.card--user--invite__icon {
	width: 50px;
	height: 50px;
	padding: 8px;
	z-index: 1;
	stroke-width: 2px;
	border-radius: 5px;
	margin-bottom: 5px;
	color: var(--primary-color);
	border: 1px dotted var(--primary-color);
}

.card--portal__header {
	width: 100%;
	height: 120px;
	padding: 10px;
	position: relative;
	background-color: var(--secondary-color);
}

.card--portal__body {
	flex-grow: 1;
	padding: 10px;
}

.card--portal__title {
	font-size: 18px;
	font-weight: 500;
}

.card--portal__footer {
	display: flex;
	padding: 10px;
	color: var(--info-color);
	font-size: 12px;
	align-items: center;
	justify-content: space-between;
}

.card--portal__properties {
	top: 5px;
	right: 5px;
	display: flex;
	column-gap: 5px;
	position: absolute;
	align-items: center;
}

.card--portal__title {
	font-size: 17px;
	font-weight: 500;
	word-break: break-all;
}

.card--user {
	width: 280px;
	height: 260px;
	display: flex;
	cursor: pointer;
	flex-direction: column;
}

.card--user__header {
	padding: 10px;
	position: relative;
}

.card--user__properties {
	top: 5px;
	right: 8px;
	display: flex;
	position: absolute;
	align-items: center;
}

.card--user__image {
	width: 100px;
	height: 100px;
	display: block;
	margin: 0 auto;
	border-radius: 50%;
	border: 1px solid var(--primary-border-color);
}

.card--user__body {
	flex-grow: 1;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 0 10px 10px 10px;
}

.card--user__footer {
	display: flex;
	padding: 10px;
	color: var(--info-color);
	font-size: 12px;
	align-items: center;
	justify-content: flex-end;
}

.card--external-app {
	width: 200px;
	height: 150px;
	padding: 10px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.card--external-app__icon {
	width: 60px;
	height: 60px;
}

.card--external-app__text {
	line-height: 0;
	margin: 10px 0;
}
.card--external-app__btn {
	padding: 6px 16px;
	font-size: 12px;
}

.card--profile--lead-gen--template-creation {
	width: 500px;
	padding: 20px;
}
.card--profile--lead-gen--template-creation__button {
	display: block;
	margin: 0 auto;
}

.card--profile-lead-gen__zoho-crm-field {
	cursor: move;
	padding: 4px 8px;
	box-shadow: none;
	border-radius: 5px;
	margin-bottom: 10px;
	background-color: #fff;
	border: 1px solid var(--primary-border-color);
}
.card--profile-lead-gen__zoho-crm-field--dragging {
	opacity: 0.5;
}

.card--profile-lead-gen__system-field {
	cursor: move;
	display: flex;
	padding: 4px 8px;
	box-shadow: none;
	border-radius: 5px;
	align-items: center;
	margin-bottom: 10px;
	background-color: #fff;
	border: 1px solid var(--primary-border-color);
}

.card--profile-lead-gen__system-field--dragging {
	opacity: 0;
}

.card--profile-lead-gen__system-field__title {
	margin-right: auto;
}

.card--profile-lead-gen__system-field__icon {
	width: 17px;
	height: 17px;
	cursor: pointer;
	margin-left: 10px;
	color: var(--primary-border-color);
}

.card--profile-lead-gen__system-field__icon:hover {
	color: var(--primary-color);
}

.card--profile-lead-gen__preview {
	width: 100%;
	padding: 20px;
	min-width: 400px;
}

.card--profile-template--message {
	padding: 10px;
	max-width: 400px;
	border-radius: 5px;
}
