.template__container--action-buttons {
	display: flex;
	column-gap: 10px;
	align-items: center;
	justify-content: center;
}

.template__image {
	width: 160px;
	height: 160px;
	display: block;
	overflow: hidden;
	border-radius: 50%;
	margin: 0 auto 15px;
	border: 1px solid #e5e5e5;
}
.template__name {
	font-size: 16px;
	font-weight: 600;
	text-align: center;
	margin-bottom: 15px;
}
.template__about {
	font-size: 14px;
	color: #777777;
	text-align: justify;
	margin-bottom: 15px;
}
.template__key-value__group {
	margin-bottom: 15px;
}
.template__key-value__label {
	font-size: 15px;
	margin-bottom: 5px;
}
.template__key-value__value {
	font-size: 14px;
	padding: 8px 16px;
	border-radius: 10px;
	background-color: #f3f3f3;
}
.template__social-urls {
	display: flex;
	column-gap: 20px;
	margin-bottom: 20px;
	align-items: center;
	justify-content: center;
}
.template__icon {
	width: 25px;
	height: 25px;
	color: #b7bbc0;
}
.template__icon--mail:hover {
	color: #101f36;
}
