.table__container {
	overflow: auto;
	height: calc(100vh - 170px);
}

.table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0;
}

.table__header {
	top: 0;
	color: #fff;
	position: sticky;
	font-weight: 500;
	font-size: 15px;
	text-align: left;
	padding: 8px 12px;
	vertical-align: middle;
	background: var(--primary-color);
	border-bottom: 1px solid var(--primary-color);
}

.table__cell {
	font-weight: 400;
	text-align: left;
	font-size: 14px;
	padding: 8px 12px;
	word-break: break-all;
	vertical-align: middle;
	border-bottom: 1px solid var(--primary-border-color);
}

.table__cell:first-child {
	border-left: 1px solid var(--primary-border-color);
}

.table__cell:last-child {
	border-right: 1px solid var(--primary-border-color);
}
