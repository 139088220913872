
.toast {
	color: var(--toast-text-color);
	background-color: red;
	border-radius: 8px;
	overflow: hidden;
	border-left: 8px solid transparent;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
		rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.toast__container {
	display: flex;
	min-width: 350px;
	align-items: center;
	padding: 8px 10px;
}

.toast__icon {
	width: 28px;
	height: 28px;
	margin-right: 10px;
}

.toast__text-container {
	flex: 1;
	margin-right: 10px;
}

.toast__close-icon {
	width: 20px;
	height: 20px;
	cursor: pointer;
}

.toast--success {
	border-left-color: var(--toast-success-border-color);
	background-color: var(--toast-success-bg-color);
}

.toast--success .toast__icon,
.toast--success .toast__close-icon {
	color: var(--toast-success-icon-color);
}

.toast--info {
	border-left-color: var(--toast-info-border-color);
	background-color: var(--toast-info-bg-color);
}

.toast--info .toast__icon,
.toast--info .toast__close-icon {
	color: var(--toast-info-icon-color);
}

.toast--failure {
	border-left-color: var(--toast-failure-border-color);
	background-color: var(--toast-failure-bg-color);
}

.toast--failure .toast__icon,
.toast--failure .toast__close-icon {
	color: var(--toast-failure-icon-color);
}