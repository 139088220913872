.loader--btn {
	width: 17px;
	height: 17px;
	animation: spin 1s linear infinite;
}

.loader--btn--sm {
	width: 13px;
	height: 13px;
}

.loader--btn--primary {
	color: white;
}

.loader--btn--secondary {
	color: var(--primary-color);
}

.loader--load-more {
	width: 24px;
	height: 24px;
	animation: spin 1s linear infinite;
	color: var(--primary-color);
}

.loader--modal {
	width: 28px;
	height: 28px;
	animation: spin 1s linear infinite;
	color: var(--primary-color);
}
