.badge {
	font-size: 10px;
	padding: 2px 6px;
	border-radius: 8px;
    width: max-content;
}

.badge--green {
	color: var(--badge-green-color);
	background-color: var(--badge-green-bg-color);
}

.badge--orange {
	color: var(--badge-orange-color);
	background-color: var(--badge-orange-bg-color);
}
.badge--grey {
	color: var(--badge-grey-color);
	background-color: var(--badge-grey-bg-color);
}

.badge--blue {
	color: var(--badge-blue-color);
	background-color: var(--badge-blue-bg-color);
}