/* @tailwind base;
@tailwind components;
@tailwind utilities; */

:root {
	--primary-color: #1c3f8b;
	--primary-border-color: #d5d5d5;

	--secondary-color:#1c3f8b25;

	--info-color:#c2c2c2;
	
	--white-color: #ffffff;
	--grey-color-100: #e5e5e5;
	--sidebar-primay-bg-color: #172d50;
	--sidebar-secondary-bg-color: #101f36;
	--info-color:#a2a2a2;
	--error-color: #d42946;
	
	--navlink-primary-inactive-color: #c3c3c3;
	--navlink-secondary-inactive-color: #c3c3c3;
	--navlink-primary-bg-color: rgba(255, 255, 255, 0.1);
	--navlink-secondary-bg-color: rgba(255, 255, 255, 0.1);
	
	--input-error-color: #d42946;
	--input-error-border-color: #d42946;
	--input-primary-label-color: #767474;
	--input-primary-border-color: #e8eaec;
	--input-primary-focus-border-color: #16964b;
	--input-primary-placeholder-color: #9ea6b1;
	--table-primary-header-bg-color: #fff;
	--table-primary-header-text-color: #1f2534;
	--table-primary-header-border-color: #b6b6b6;
	--table-primary-table-data-text-color:#4d4f51;
	--table-primary-table-data-border-color:#b6b6b6;

	

	--toast-text-color: #484e4b;

	--toast-success-bg-color: #c5f7dc;
	--toast-success-icon-color: #3bc279;
	--toast-success-border-color: #3bc279;

	--toast-failure-bg-color: #fecfca;
	--toast-failure-icon-color: #e9594b;
	--toast-failure-border-color: #e9594b;

	--toast-info-bg-color: #cde1fe;
	--toast-info-icon-color: #3e83e5;
	--toast-info-border-color: #3e83e5;

	--badge-green-color: #38ad5b;
	--badge-green-bg-color: #dcfce6;

	--badge-orange-color: #f9781f;
	--badge-orange-bg-color: #ffedd5;

	--badge-blue-color: #3e83e5;
	--badge-blue-bg-color: #cde1fe;

	--badge-grey-color: #4b5563;
	--badge-grey-bg-color: #f3f4f6;

	--form-field-label-color: #6b6a6a;
	--form-field-error-color: #f55c6b;
	--form-field-error-border-color: #f55c6b;
	--form-field-input-placeholder-color: #9a9898;

	--modal-backdrop-bg-color: #080c16;

}

* {
	margin: 0;
	font-weight: 400;
	font-family: 'Poppins';
	box-sizing: border-box;
}
a {
	text-decoration: none;
	color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 600;
}

button {
	border: none;
	outline: none;
	cursor: pointer;
	color: inherit;
	background: none;
}

button:disabled {
	cursor: not-allowed;
	opacity: 0.5;
}

textarea,
input {
	outline: none;
	resize: none;
}

ul{
	list-style: none;
}