.no-templates-card {
	padding: 20px;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.templates-container {
	width: 500px;
	border-right: 1px solid var(--primary-border-color);
}

.templates-container__header,
.preview-container__header {
	padding: 10px 20px;
	border-bottom: 1px solid var(--primary-border-color);
}

.templates-container__title,
.preview-container__title {
	font-size: 20px;
	font-weight: 600;
}

.templates-container__body {
	padding: 20px;
	overflow: auto;
	height: calc(100vh - 210px);
}

.template {
	width: 450px;
	height: 250px;
	display: block;
	cursor: pointer;
	overflow: hidden;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.template--active {
	border: 1px solid var(--primary-color);
}

.template:last-child {
	margin-bottom: 0;
}

.preview-container {
	width: calc(100vw - 625px);
}

.preview-container__body {
	padding: 20px;
	overflow: auto;
	height: calc(100vh - 270px);
}

.preview-container__footer {
	gap: 10px;
	height: 60px;
	display: flex;
	padding: 10px 20px;
	align-items: center;
	justify-content: flex-end;
	border-top: 1px solid var(--primary-border-color);
}

