.btn {
	display: flex;
	column-gap: 5px;
	font-size: 14px;
	padding: 6px 14px;
	border-radius: 5px;
	align-items: center;
}
.btn--sm {
	font-size: 12px;
	padding: 5px 15px;
}

.btn--primary {
	color: #ffffff;
	border: 1px solid var(--primary-color);
	background-color: var(--primary-color);
}

.btn--secondary {
	color: var(--primary-color);
	border: 1px solid var(--primary-color);
	background-color: var(--secondary-color);
}

.btn--outlined {
	color: var(--primary-color);
	border: 1px solid var(--primary-color);
}

.btn__text {
	display: block;
}
