.portals__bg {
	top: 56px;
	left: 0;
	right: 0;
	z-index: -1;
	height: 300px;
	min-width: 600px;
	position: absolute;
	background-color: var(--primary-color);
}





