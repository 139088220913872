.form__field-group--single {
	margin-bottom: 15px;
}

.form__field-group--multiple {
	display: flex;
	column-gap: 15px;
	margin-bottom: 15px;
}

.form__field-sub-group {
	flex: 1;
}

.form__field-label {
	display: block;
	font-size: 14px;
	font-weight: 400;
	color: var(--form-field-label-color);
}

.form__field-error {
	font-size: 13px;
	color: var(--form-field-error-color);
}

.form__field-input {
	width: 100%;
	font-size: 14px;
	padding: 6px 14px;
	border-radius: 8px;
	border: 1px solid var(--primary-border-color);
}

.form__field-input--phone {
	width: 100% !important;
	font-size: 14px;
	padding-top: 8px !important;
	padding-bottom: 8px !important;
	border: 1px solid var(--primary-border-color) !important;
	border-radius: 8px;
}

.form__field-input:focus {
	border-color: var(--primary-color);
}

.form__field-input--error,
.form__field-input--error:focus,
.form__field-input--image__container--error {
	border-color: var(--form-field-error-border-color);
}

.form__field-input::placeholder,
.form__field-input--image__placeholder {
	font-weight: 300;
	color: var(--form-field-input-placeholder-color);
}

.form__field-input--image__container {
	width: 100%;
	display: flex;
	font-size: 14px;
	overflow: hidden;
	border-radius: 8px;
	border: 1px solid var(--primary-border-color);
}

.form__field-input--image__btn {
	padding: 6px 14px;
	font-size: 14px;
	color: var(--white-color);
	background-color: var(--primary-color);
}

.form__field-input--image__text {
	padding: 6px 10px;
	font-size: 14px;
}

.form__field-input--image__input {
	display: none;
}

.form__field-input--checkbox {
	display: none;
}
.form__field-input--checkbox__container {
	display: flex;
	column-gap: 10px;
	align-items: center;
}

.form__field-input--checkbox__icon {
	width: 16px;
	height: 16px;
	display: block;
	cursor: pointer;
	position: relative;
	border-radius: 2px;
	border: 1px solid var(--primary-border-color);
}

.form__field-input--checkbox:checked
	+ .form__field-input--checkbox__icon::after {
	content: '';
	left: 4px;
	bottom: 2px;
	width: 4px;
	height: 10px;
	position: absolute;
	border: solid var(--white-color);
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}

.form__field-input--checkbox:checked + .form__field-input--checkbox__icon {
	border-color: var(--primary-color);
	background-color: var(--primary-color);
}

.form__field-input--checkbox__label {
	font-size: 14px;
	font-weight: 300;
	color: var(--form-field-label-color);
}
